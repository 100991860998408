import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import axios from "axios";

class CryptocurrencyAnalytics extends Component {
  state = {
    series: [],
    count: 5,
    options: {
      labels: [],
      chart: {
        width: 380,
        type: "pie",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "bottom",
      },
      //   colors: ["#5664d2", "#1cbb8c", "#eeb902"],
    },
  };
  async fetchAnalytics() {
    try {
      const response = await axios.get(`/api/v2/admin/charts/cryptolytics?limit=${this.state.count}`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);
      let options = { ...this.state.options };
      options.labels = response.data.labels;
      const series = response.data.countArr.map((x) => (x = Number(x)));
      this.setState({
        options,
        series,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    this.fetchAnalytics();
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-end">
              <select
                className="form-select form-select-sm"
                onChange={(e) => {
                  this.setState(
                    {
                      count: e.target.value,
                    },
                    () => {
                      this.fetchAnalytics();
                    }
                  );
                }}
                value={this.state.count}
              >
                <option value="5">Top 5</option>
                <option value="10">Top 10</option>
                <option value="15">Top 15</option>
                <option value="20">Top 20</option>
              </select>
            </div>
            <h4 className="card-title mb-4">Cryptocurrency Analytics</h4>

            <div id="donut-chart" className="apex-charts">
              <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="350" />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default CryptocurrencyAnalytics;
