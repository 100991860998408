import React, { Component } from "react";
import { VectorMap } from "react-jvectormap";
import "./jquery-jvectormap.css";
import axios from "axios";
export class Vectormap extends Component {
  state = {
    locations: [],
  };
  componentDidMount() {
    const fetchLocations = async () => {
      const response = await axios.get("api/v2/admin/dashboard/locations", {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      this.setState({
        locations: response.data.locations,
      });
    };
    fetchLocations();
  }
  render() {
    return (
      <div style={{ width: this.props.width, height: 250 }}>
        <VectorMap
          map={this.props.value}
          backgroundColor="transparent"
          ref="map"
          containerStyle={{
            width: "100%",
            height: "80%",
          }}
          regionStyle={{
            initial: {
              fill: this.props.color,
              stroke: "#74788d",
              "stroke-width": 1,
              "stroke-opacity": 0.4,
            },
            hover: {
              "fill-opacity": 0.8,
              cursor: "pointer",
            },
            selected: {
              fill: "#2938bc", //what colour clicked country will be
            },
            markerStyle: {
              initial: {
                fill: "#F8E23B",
                stroke: "#383f47",
              },
            },
            selectedHover: {},
          }}
          markers={this.state.locations}
          containerClassName="map"
        />
      </div>
    );
  }
}

export default Vectormap;
