import React, { Component } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import User from "./User";

// import TotalUsers from "./TotalUsers";
// import numberWithCommas from "../Dashboard/numberWithCommas";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
class ViewUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      count: 0,
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "View Users", link: "#" },
      ],
    };
    this.setCount = this.setCount.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
  }
  setCount() {
    this.setState({
      count: this.state.count + 1,
    });
  }
  toggleLoader() {
    this.setState({
      open: !this.state.open,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.count !== this.state.count) {
      if (this.state.count >= 3) {
        this.setState({
          open: false,
        });
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="View Users" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <User toggleLoader={this.toggleLoader} setCount={this.setCount} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ViewUsers;
