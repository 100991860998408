import React, { Component } from "react";
import { Label, FormGroup, Button, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import "./CTT.scss";
import { Icon } from "@iconify/react";

class CTTForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      modal: false,
      confirmation: {
        open: false,
        message: "",
      },
      users: [],
      datalist: [],
      searchUser: {
        name: "",
        email: "",
        ctt: 0,
      },
    };
    this.toggle = this.toggle.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.search = this.search.bind(this);
    this.debit = this.debit.bind(this);
    this.credit = this.credit.bind(this);
  }
  toggle() {
    this.setState({
      modal: false,
    });
  }
  toggleConfirmation() {
    this.setState({
      confirmation: {
        open: false,
      },
    });
  }
  async fetchUsers() {
    try {
      const response = await axios.get(`/api/v2/admin/users?range=50`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      this.setState({
        users: response.data.users,
      });
    } catch (error) {
      console.log(error);
    }
  }
  search(e) {
    if (this.state.searchUser.name.length === 0) {
      this.setState({
        datalist: [],
      });
    } else {
      const users = [...this.state.users];
      const datalist = users.filter((user) => {
        return user.full_name.toLowerCase().indexOf(this.state.searchUser.name.toLowerCase()) > -1;
      });
      this.setState({
        datalist,
      });
    }
  }
  async credit() {
    try {
      const response = await axios.post(
        `/api/v2/admin/ctt/credit`,
        {
          email: this.state.searchUser.email,
          ctt_amt: this.state.searchUser.ctt,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        }
      );
      // console.log(response.data);
      this.fetchUsers();
      this.setState({
        datalist: [],
        searchUser: {
          name: "",
          email: "",
          ctt: "",
        },
        modal: false,
        confirmation: {
          open: true,
          message: response.data.msg,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }
  async debit() {
    try {
      const response = await axios.post(
        `/api/v2/admin/ctt/debit`,
        {
          email: this.state.searchUser.email,
          ctt_amt: this.state.searchUser.ctt,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        }
      );
      // console.log(response.data);
      this.fetchUsers();
      this.setState({
        datalist: [],
        searchUser: {
          name: "",
          email: "",
          ctt: "",
        },
        confirmation: {
          open: true,
          message: response.data.msg,
        },
        modal: false,
      });
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    this.fetchUsers();
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>CTT</ModalHeader>
          <ModalBody>
            <FormGroup className="mb-2" style={{ position: "relative" }}>
              <Label for="user">Search for a user</Label>

              <input
                list="users"
                name="username"
                id="username"
                className="form-control"
                placeholder="Enter a name"
                onFocus={this.search}
                onBlur={(e) => {
                  if (!this.state.searchUser.name) {
                    this.setState({
                      datalist: [],
                    });
                  }
                }}
                value={this.state.searchUser.name}
                onChange={(e) => {
                  this.setState(
                    {
                      searchUser: {
                        ...this.state.searchUser,
                        name: e.target.value,
                      },
                    },
                    () => this.search()
                  );
                }}
                autoComplete="off"
              />
              <div className={"datalist " + (this.state.datalist.length > 0 ? "show" : "hide")}>
                {this.state.datalist.map((data, idx) => {
                  return (
                    <span
                      key={idx}
                      onClick={() => {
                        this.setState({
                          searchUser: {
                            name: data.full_name,
                            email: data.email,
                            ctt: data.ctt,
                          },
                          datalist: [],
                        });
                      }}
                    >
                      {data.full_name}
                    </span>
                  );
                })}
              </div>
            </FormGroup>
            <FormGroup className="d-flex justify-content-between mb-3 w-100">
              <Label for="ctt" style={{ position: "relative", top: 10 }}>
                CTT
              </Label>
              <Input
                type="number"
                name="ctt"
                id="ctt"
                placeholder="Enter CTT"
                className="w-50"
                value={Number(this.state.searchUser.ctt)}
                onChange={(e) =>
                  this.setState({
                    searchUser: {
                      ...this.state.searchUser,
                      ctt: e.target.value,
                    },
                  })
                }
                style={{ position: "relative", left: 10 }}
              />
            </FormGroup>
            <FormGroup className="text-center mb-2">
              <Button color="success" className="me-2" onClick={this.credit}>
                Credit
              </Button>
              <Button color="danger" className="me-2" onClick={this.debit}>
                Debit
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.confirmation.open} toggle={this.toggleConfirmation}>
          <ModalHeader toggle={this.toggleConfirmation}>
            <Icon icon="akar-icons:circle-check" /> Successful
          </ModalHeader>
          <ModalBody>{this.state.confirmation.message}</ModalBody>
        </Modal>
        <div className="body">
          <h4 className="card-title mb-4">Credit / Debit CTT</h4>

          <p>Credit / Debit CTT to / from users using the CTT Form below. </p>
          <Button color="primary" onClick={() => this.setState({ modal: true })}>
            Open Form
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default CTTForm;
