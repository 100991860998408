import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "../Dashboard/dashboard.scss";
import axios from "axios";

class CTTReports extends Component {
  state = {
    menu: false,
    series: [0],
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#5664d2"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series2: [0],
    options2: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#1cbb8c"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series3: [0],
    options3: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FFE160"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
  };
  componentDidMount() {
    const fetchCTTAnalytics = async () => {
      try {
        const response = await axios.get("/api/v2/admin/charts/cttAnalytics", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        this.props.setCount();

        this.setState({
          series: [response.data.daily_login],
          series2: [response.data.video_ad],
          series3: [response.data.redeem],
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchCTTAnalytics();
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">CTT Reports</h4>
            <div className="text-center">
              <Row>
                <Col sm={4}>
                  <div>
                    <div className="mb-3">
                      <div id="radialchart-1" className="apex-charts">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height="60" />
                      </div>
                    </div>

                    <p className="text-muted text-truncate mb-2">Daily Login</p>
                    <h5>{this.state.series[0]}%</h5>
                  </div>
                </Col>

                <Col sm={4}>
                  <div className="mt-5 mt-sm-0">
                    <div className="mb-3">
                      <div id="radialchart-2" className="apex-charts">
                        <ReactApexChart options={this.state.options2} series={this.state.series2} type="radialBar" height="60" />
                      </div>
                    </div>

                    <p className="text-muted text-truncate mb-2">Video Ad</p>
                    <h5>{this.state.series2[0]}%</h5>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="mt-5 mt-sm-0">
                    <div className="mb-3">
                      <div id="radialchart-2" className="apex-charts">
                        <ReactApexChart options={this.state.options3} series={this.state.series3} type="radialBar" height="60" />
                      </div>
                    </div>

                    <p className="text-muted text-truncate mb-2">Redemption</p>
                    <h5>{this.state.series3[0]}%</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default CTTReports;
