import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import TransactionAnalytics from "./TransactionAnalytics";
import CryptocurrencyAnalytics from "./CryptocurrencyAnalytics";
import CTTReports from "./CTTReports";
import Events from "./Events";
import RecentlyActivity from "./RecentlyActivity";
import UsersByLocation from "./UsersByLocation";
// import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import axios from "axios";
import numberWithCommas from "./numberWithCommas";

axios.defaults.baseURL = "https://cryptotrainertestapi.finstreet.in";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "Dashboard", link: "#" },
      ],
      reports: [
        { icon: "fa-solid:users", title: "Total Users", value: 0, rate: "0%", desc: "" },
        { icon: "fa-solid:money-bill-wave", title: "Total Transactions", value: "0", rate: "0%", desc: "" },
        { icon: "ri-briefcase-4-line", title: "Average Buy Price", value: "₹0", rate: "0%", desc: "" },
      ],
    };
  }
  componentDidMount() {
    const getTotalUsers = async () => {
      try {
        const response = await axios.get("/api/v2/admin/dashboard/users", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        let reports = [...this.state.reports];
        reports[0].value = numberWithCommas(parseInt(response.data.users));
        reports[0].rate = response.data.change_percent_in_24h;
        this.setState({
          reports: reports,
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getTotalTransactions = async () => {
      try {
        const response = await axios.get("/api/v2/admin/dashboard/purchases", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        let reports = [...this.state.reports];
        reports[1].value = `${numberWithCommas(response.data.purchases)}`;
        reports[1].rate = response.data.change_percent_in_24h;

        this.setState({
          reports: reports,
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getAveragePrice = async () => {
      try {
        const response = await axios.get("/api/v2/admin/dashboard/averagePrice", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        let reports = [...this.state.reports];
        reports[2].value = `${Number(response.data.avg_price).toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        })}`;
        reports[2].rate = response.data.change_percent_in_24h;

        this.setState({
          reports: reports,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getTotalUsers();
    getTotalTransactions();
    getAveragePrice();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <Col xl={8}>
                <Row>
                  <MiniWidgets reports={this.state.reports} />
                </Row>

                {/* revenue Analytics */}
                <TransactionAnalytics />
              </Col>

              <Col xl={4}>
                {/* sales Analytics */}
                <CryptocurrencyAnalytics />

                {/* earning reports */}
                <CTTReports />
              </Col>
            </Row>

            <Row>
              {/* sources */}
              <Events />

              {/* recent activity */}
              <RecentlyActivity />

              {/* revenue by locations */}
              <UsersByLocation />
            </Row>

            <Row>
              {/* chat box */}
              {/* <ChatBox /> */}

              {/* latest transactions */}
              <LatestTransactions />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
