import React, { Component } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "../Dashboard/dashboard.scss";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";

import CircularProgress from "@mui/material/CircularProgress";
class Currency extends Component {
  state = {
    open: true,
    breadcrumbItems: [
      { title: "Crypto Trainer", link: "/" },
      { title: "Currency", link: "#" },
    ],
    series: [],
    count: 5,
    options: {
      labels: [],
      chart: {
        width: 380,
        type: "pie",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "bottom",
      },
      //   colors: ["#5664d2", "#1cbb8c", "#eeb902"],
    },
    series2: [],
    count2: 5,
    options2: {
      labels: [],
      chart: {
        width: 380,
        type: "pie",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "bottom",
      },
      //   colors: ["#5664d2", "#1cbb8c", "#eeb902"],
    },
  };
  async fetchAnalytics2() {
    const response = await axios.get(`/api/v2/admin/currency/transactions?limit=${this.state.count2}`, {
      headers: {
        "x-auth-token": localStorage.getItem("authToken"),
      },
    });

    let options2 = { ...this.state.options2 };
    options2.labels = response.data.labels;
    const series2 = response.data.countArr.map((x) => (x = Number(x)));
    this.setState({
      options2,
      series2,
    });
  }
  async fetchAnalytics() {
    const response = await axios.get(`/api/v2/admin/currency?limit=${this.state.count}`, {
      headers: {
        "x-auth-token": localStorage.getItem("authToken"),
      },
    });

    let options = { ...this.state.options };
    options.labels = response.data.labels;
    const series = response.data.countArr.map((x) => (x = Number(x)));
    this.setState({
      options,
      series,
      open: false,
    });
  }
  componentDidMount() {
    this.fetchAnalytics();
    this.fetchAnalytics2();
  }
  render() {
    return (
      <div className="page-content">
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>
        <Container fluid>
          <Breadcrumbs title="Currency" breadcrumbItems={this.state.breadcrumbItems} />

          <Row>
            <Card>
              <CardBody>
                <div className="float-end">
                  <select
                    className="form-select form-select-sm"
                    onChange={(e) => {
                      this.setState(
                        {
                          count: e.target.value,
                        },
                        () => {
                          this.fetchAnalytics();
                        }
                      );
                    }}
                    value={this.state.count}
                  >
                    <option value="5">Top 5</option>
                    <option value="10">Top 10</option>
                    <option value="15">Top 15</option>
                    <option value="20">Top 20</option>
                  </select>
                </div>
                <h4 className="card-title mb-4">Currency Preference Analytics</h4>

                <div id="donut-chart" className="apex-charts">
                  <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="350" />
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="float-end">
                  <select
                    className="form-select form-select-sm"
                    onChange={(e) => {
                      this.setState(
                        {
                          count2: e.target.value,
                        },
                        () => {
                          this.fetchAnalytics2();
                        }
                      );
                    }}
                    value={this.state.count2}
                  >
                    <option value="5">Top 5</option>
                    <option value="10">Top 10</option>
                    <option value="15">Top 15</option>
                    <option value="20">Top 20</option>
                  </select>
                </div>
                <h4 className="card-title mb-4">Transaction Currency Preference Analytics</h4>

                <div id="donut-chart" className="apex-charts">
                  <ReactApexChart options={this.state.options2} series={this.state.series2} type="donut" height="350" />
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Currency;
