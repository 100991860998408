import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Row } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import axios from "axios";
import numberWithCommas from "../Dashboard/numberWithCommas";

class UserGraphs extends Component {
  state = {
    total_users: 0,
    yesterday: 0,
    month: 0,
    year: 0,
    all_time: 0,
    series: [0],
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#5664d2"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series2: [0],
    options2: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#1cbb8c"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series3: [0],
    options3: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FFE160"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series4: [0],
    options4: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FF4560"],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
  };
  componentDidMount() {
    const fetchUserAnalytics = async () => {
      try {
        const response = await axios.get("/api/v2/admin/charts/usersAnalytics", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        this.props.setCount();

        // console.log(response.data);
        this.setState({
          series: [response.data.yesterday.percent],
          series2: [response.data.month.percent],
          series3: [response.data.year.percent],
          series4: [response.data.all_time.percent],
          yesterday: numberWithCommas(response.data.yesterday.count),
          month: numberWithCommas(response.data.month.count),
          year: numberWithCommas(response.data.year.count),
          all_time: numberWithCommas(response.data.all_time.count),
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserAnalytics();
  }
  render() {
    console.log(this.props);
    return (
      <Card style={{ height: "94%" }}>
        <CardBody className="pt-3 pt-sm-4 pt-md-5">
          <Row>
            <Col sm={6}>
              <div>
                <div className="mb-3">
                  <div id="radialchart-1" className="apex-charts">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height="70" />
                  </div>
                </div>

                <p className="text-muted text-center mb-2">Yesterday</p>
                <h5 className="text-center">{this.state.yesterday}</h5>
              </div>
            </Col>

            <Col sm={6}>
              <div className=" mt-sm-0">
                <div className="mb-3">
                  <div id="radialchart-2" className="apex-charts">
                    <ReactApexChart options={this.state.options2} series={this.state.series2} type="radialBar" height="70" />
                  </div>
                </div>

                <p className="text-muted text-center mb-2">This Month</p>
                <h5 className="text-center">{this.state.month}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div className=" mt-sm-0">
                <div className="mb-3">
                  <div id="radialchart-2" className="apex-charts">
                    <ReactApexChart options={this.state.options3} series={this.state.series3} type="radialBar" height="70" />
                  </div>
                </div>

                <p className="text-muted text-center mb-2">This Year</p>
                <h5 className="text-center">{this.state.year}</h5>
              </div>
            </Col>
            <Col sm={6}>
              <div className=" mt-sm-0">
                <div className="mb-3">
                  <div id="radialchart-2" className="apex-charts">
                    <ReactApexChart options={this.state.options4} series={this.state.series4} type="radialBar" height="70" />
                  </div>
                </div>

                <p className="text-muted text-center mb-2">All time</p>
                <h5 className="text-center">{this.state.all_time}</h5>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default UserGraphs;
