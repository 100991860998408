import React, { Component } from "react";

import { Col, Card, CardBody, Container } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import axios from "axios";
import moment from "moment";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";

import CircularProgress from "@mui/material/CircularProgress";
class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "View Transactions", link: "#" },
      ],
      open: true,
      menu: false,
      rows: [],
    };
  }

  componentDidMount() {
    const getTransactions = async () => {
      try {
        const response = await axios.get(`/api/v2/admin/dashboard/transactions`, {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        let rows = [];
        response.data.transactions.forEach((t) => {
          const date = moment(t.transaction_date).format("Do MMM YY");
          rows.push({
            r_id: t.reference_id,
            customer: t.full_name,
            name: t.crypto_name,
            quantity: t.quantity,
            date: date,
            current_price: `₹${t.crypto_current_price}`,
            total_price: `₹${Math.abs(Number(t.total_price))}`,
            currency: t.currency.toUpperCase(),
            type: t.transaction_type === "Buy" ? <div className="badge badge-soft-success font-size-12">Buy</div> : <div className="badge badge-soft-danger font-size-12">Sell</div>,
          });
        });
        this.setState({
          rows,
          open: false,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getTransactions();
  }
  render() {
    const data = {
      columns: [
        {
          dataField: "r_id",
          text: "Reference ID",
        },
        {
          dataField: "customer",
          text: "Customer Name",
        },
        {
          dataField: "name",
          text: "Name",
        },

        {
          dataField: "quantity",
          text: "Quantity",
        },
        {
          dataField: "date",
          text: "Date",
        },

        {
          dataField: "current_price",
          text: "Current Price",
        },
        {
          dataField: "total_price",
          text: "Total Price",
        },
        {
          dataField: "currency",
          text: "Currency",
        },
        {
          dataField: "type",
          text: "Type",
        },
      ],
      rows: this.state.rows,
    };

    const options = {
      hideSizePerPage: true,
      hidePageListOnlyOnePage: false,
    };
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="View Transactions" breadcrumbItems={this.state.breadcrumbItems} />
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="float-end">
                    <Link to="#" className="btn btn-primary btn-sm">
                      View more
                    </Link>
                  </div>
                  <h4 className="card-title mb-4">Latest Transactions</h4>
                  <div className="table-responsive">
                    <SimpleBar style={{ maxWidth: "100%", maxHeight: "100%" }}>
                      <BootstrapTable keyField="id" data={data.rows} columns={data.columns} pagination={paginationFactory(options)} />
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default View;
