export const FORGET_USER = "login_user";
export const FORGET_USER_SUCCESSFUL = "forget_user_successfull";
export const FORGET_PASSWORD_API_FAILED = "FORGET_PASSWORD_API_FAILED";

export const VALIDATE_CODE = "VALIDATE_CODE";
export const VALIDATE_CODE_SUCCESSFUL = "VALIDATE_CODE_SUCCESSFUL";
export const VALIDATE_CODE_FAILED = "VALIDATE_CODE_FAILED";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESSFUL = "CHANGE_PASSWORD_SUCCESSFUL";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
