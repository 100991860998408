import React, { Component } from "react";
import { Col, Card, CardBody } from "reactstrap";
import ReactTooltip from "react-tooltip";
import numberWithCommas from "./numberWithCommas";

class MiniWidgets extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.reports.map((report, key) => (
          <Col key={key} md={4}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">{report.title}</p>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                    <h4 className="count mb-0" data-tip={report.value}>
                      {report.value}
                    </h4>
                  </div>
                  <div className="text-primary">
                    <i class="iconify font-size-24" data-icon={report.icon} style={{ color: "#5664d2" }}></i>
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-3">
                <div className="text-truncate">
                  {parseInt(report.rate) >= 0 ? (
                    <span className="badge badge-soft-success font-size-11 me-1">
                      <i className="mdi mdi-menu-up"> </i> {numberWithCommas(report.rate)}%
                    </span>
                  ) : (
                    <span className="badge badge-soft-danger font-size-11 me-1">
                      <i className="mdi mdi-menu-down"> </i> {numberWithCommas(report.rate)}%
                    </span>
                  )}
                  <span className="text-muted ms-2">{report.desc}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default MiniWidgets;
