import React, { Component } from "react";

import { Col, Card, CardBody, Container, Form, FormGroup, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import axios from "axios";
import SimpleBar from "simplebar-react";
import numberWithCommas from "../Dashboard/numberWithCommas";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Icon } from "@iconify/react";

class AddTrending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "Add Trending", link: "#" },
      ],
      rows: [],
      search_term: "",
      open: true,
      modal: false,
      crypto_id: "",
    };
    this.getTrending = this.getTrending.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addTrending = this.addTrending.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  async addTrending(id) {
    const response = await axios.post(
      `/api/v2/admin/trending/add`,
      {
        crypto_id: id,
      },
      {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      }
    );
    if (response.status === 200) {
      this.setState({
        modal: false,
      });
    }
  }
  async getTrending() {
    try {
      const response = await axios.get(`/api/v2/admin/market/supported`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);
      let rows = [];
      this.setState({ open: false });
      response.data.coins.forEach((t) => {
        rows.push({
          profile: <img src={t.crypto_image} width="30" height="30" style={{ borderRadius: "100%" }} alt="" />,
          crypto_name: t.crypto_name,
          crypto_symbol: t.crypto_symbol,
          crypto_price: `₹${numberWithCommas(t.crypto_price.slice(1, t.crypto_price.length - 1))}`,
          action: t.is_trending ? (
            <Icon icon="akar-icons:circle-check-fill" width="25" />
          ) : (
            <Icon icon="akar-icons:circle-plus-fill" width="25" style={{ cursor: "pointer" }} onClick={() => this.setState({ modal: true, crypto_id: t.crypto_id })} />
          ),
        });
      });
      this.setState({
        rows,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async handleSubmit(e) {
    if (e) e.preventDefault();
    try {
      this.setState({ open: true });
      const response = await axios.get(`/api/v2/admin/market/search?search=${this.state.search_term}`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);
      let rows = [];
      response.data.currencies.forEach((t) => {
        rows.push({
          profile: <img src={t.crypto_image} width="30" height="30" style={{ borderRadius: "100%" }} alt="" />,
          crypto_name: t.crypto_name,
          crypto_symbol: t.crypto_symbol,
          crypto_price: `₹${numberWithCommas(t.crypto_price.slice(1, t.crypto_price.length - 1))}`,
          action: t.is_trending ? (
            <Icon icon="akar-icons:circle-check-fill" width="25" />
          ) : (
            <Icon icon="akar-icons:circle-plus-fill" width="25" style={{ cursor: "pointer" }} onClick={() => this.setState({ modal: true, crypto_id: t.crypto_id })} />
          ),
        });
      });
      this.setState({
        rows,
        open: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    this.getTrending();
  }
  render() {
    const data = {
      columns: [
        {
          dataField: "profile",
          text: "Crypto Image",
        },
        {
          dataField: "crypto_name",
          text: "Crypto Name",
        },
        {
          dataField: "crypto_symbol",
          text: "Symbol",
        },

        {
          dataField: "crypto_price",
          text: "Crypto Price",
        },
        {
          dataField: "action",
          text: "Action",
        },
      ],
      rows: this.state.rows,
    };

    const options = {
      hideSizePerPage: true,
      hidePageListOnlyOnePage: false,
    };
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to add?</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                this.addTrending(this.state.crypto_id);
              }}
              className="text-capitalize"
            >
              Proceed
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Add Trending" breadcrumbItems={this.state.breadcrumbItems} />
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form inline className="d-flex gap-2" onSubmit={this.handleSubmit}>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <Input
                        type="text"
                        placeholder="Search..."
                        value={this.state.search_term}
                        onChange={(e) => {
                          this.setState({
                            search_term: e.target.value,
                          });
                          if (e.target.value === "") {
                            this.getTrending();
                          }
                        }}
                      />
                    </FormGroup>
                    <Button style={{ background: "#5664D2" }}>Search</Button>
                  </Form>
                  <div className="table-responsive mt-3">
                    <SimpleBar style={{ maxWidth: "100%", maxHeight: "100%" }}>
                      <BootstrapTable keyField="id" data={data.rows} columns={data.columns} pagination={paginationFactory(options)} />
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AddTrending;
