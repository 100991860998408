import React, { Component } from "react";
import { Row, Card, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Vector Map Component
import Vector from "./Vectormap";
import axios from "axios";
class UsersByLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      users: [],
    };
  }
  componentDidMount() {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("api/v2/admin/dashboard/locations", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });

        this.setState({
          users: response.data.users,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }

  render() {
    return (
      <React.Fragment>
        <Col lg={4}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Users by Locations</h4>

              <div id="usa-vectormap" style={{ height: "196px" }}>
                <Vector value="world_mill" width="200" color="#e8ecf4" />
              </div>

              <Row className="justify-content-center">
                {this.state.users.map((user, index) => {
                  return (
                    <Col lg={{ size: 5 }}>
                      <div className="clearfix py-2">
                        <h5 className="float-end font-size-16 m-0">{user.count}</h5>
                        <p className="text-muted mb-0 text-truncate">{user.location} :</p>
                      </div>
                    </Col>
                  );
                })}
                {/* <Col xl={{ size: 5, offset: 1 }} md={6}>
                  <div className="mt-2">
                    <div className="clearfix py-2">
                      <h5 className="float-end font-size-16 m-0">$ 2156</h5>
                      <p className="text-muted mb-0 text-truncate">Montana :</p>
                    </div>
                    <div className="clearfix py-2">
                      <h5 className="float-end font-size-16 m-0">$ 1845</h5>
                      <p className="text-muted mb-0 text-truncate">Texas :</p>
                    </div>
                  </div>
                </Col> */}
              </Row>
              {/* <div className="text-center mt-4">
                <Link to="#" className="btn btn-primary btn-sm">
                  Learn more
                </Link>
              </div> */}
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default UsersByLocation;
