import {
  FORGET_USER,
  FORGET_USER_SUCCESSFUL,
  FORGET_PASSWORD_API_FAILED,
  VALIDATE_CODE,
  VALIDATE_CODE_FAILED,
  VALIDATE_CODE_SUCCESSFUL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESSFUL,
} from "./actionTypes";

export const forgetUser = (user, history) => {
  return {
    type: FORGET_USER,
    payload: { user, history },
  };
};

export const forgetUserSuccessful = (message) => {
  return {
    type: FORGET_USER_SUCCESSFUL,
    payload: message,
  };
};

export const userForgetPasswordError = (error) => {
  return {
    type: FORGET_PASSWORD_API_FAILED,
    payload: error,
  };
};

export const validateCode = (user) => {
  return {
    type: VALIDATE_CODE,
    payload: { user },
  };
};
export const validateCodeSuccessful = (message) => {
  return {
    type: VALIDATE_CODE_SUCCESSFUL,
    payload: message,
  };
};

export const validateCodeFailed = (error) => {
  return {
    type: VALIDATE_CODE_FAILED,
    payload: error,
  };
};

export const changePassword = (user) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { user },
  };
};
export const changePasswordSuccessful = (message) => {
  return {
    type: CHANGE_PASSWORD_SUCCESSFUL,
    payload: message,
  };
};

export const changePasswordFailed = (error) => {
  return {
    type: CHANGE_PASSWORD_FAILED,
    payload: error,
  };
};
