import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import EventCards from "./EventCards";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "Events", link: "#" },
      ],
      open: true,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      open: false,
    });
  }
  render() {
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Events" breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <EventCards toggle={this.toggle} />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Events;
