import React, { Component } from "react";
import { Card, CardBody, FormGroup, Label, Input, Row, Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { Icon } from "@iconify/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import axios from "axios";

class AddEventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      modal: {
        open: false,
        success: false,
      },
      showAddUser: false,
      title: "",
      banner_image: "",
      host_name: "",
      logo: "",
      description: "",
      invitation_code: "",
      start_date: new Date(),
      end_date: new Date(),
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
      },
    });
  }
  handleNext() {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  }
  handleBack() {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  }
  handleReset() {
    this.setState({
      activeStep: 0,
    });
  }
  async handleSubmit() {
    let data = { ...this.state };
    delete data.activeStep;
    delete data.showAddUser;
    delete data.modal;
    console.log(data);
    try {
      const response = await axios.post("/api/v2/admin/events/add", data, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response);
      if (response.status === 200) {
        this.setState({
          modal: {
            open: true,
            success: true,
          },
        });
      } else {
        this.setState({
          modal: {
            open: true,
            success: false,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const steps = [
      {
        label: "Event",
        content: (
          <React.Fragment>
            <FormGroup className="mb-2">
              <Label for="eventTitle">Event title</Label>
              <Input
                type="text"
                name="eventTitle"
                id="eventTitle"
                value={this.state.title}
                onChange={(e) =>
                  this.setState({
                    title: e.target.value,
                  })
                }
                placeholder="Enter event title"
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="banner">Event Banner</Label>
              <Input
                type="text"
                name="banner"
                id="banner"
                value={this.state.banner_image}
                onChange={(e) =>
                  this.setState({
                    banner_image: e.target.value,
                  })
                }
                placeholder="Enter banner image URL"
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="hostName">Host name</Label>
              <Input
                type="text"
                name="hostName"
                id="hostName"
                value={this.state.host_name}
                onChange={(e) =>
                  this.setState({
                    host_name: e.target.value,
                  })
                }
                placeholder="Enter host name"
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="Logo">Logo</Label>
              <Input
                type="text"
                name="Logo"
                id="Logo"
                value={this.state.logo}
                onChange={(e) =>
                  this.setState({
                    logo: e.target.value,
                  })
                }
                placeholder="Enter logo URL"
              />
            </FormGroup>
          </React.Fragment>
        ),
      },
      {
        label: "Description",
        content: (
          <React.Fragment>
            <FormGroup className="mb-2">
              <Label for="hostName">Event Desciption</Label>
              <Row>
                <Col lg={6}>
                  <textarea
                    className="form-control"
                    id="description"
                    rows="3"
                    placeholder="Enter event description"
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({
                        description: e.target.value,
                      })
                    }
                  ></textarea>
                </Col>
              </Row>
            </FormGroup>
          </React.Fragment>
        ),
      },
      {
        label: "Additional options",
        content: (
          <React.Fragment>
            <FormGroup className="mb-3">
              <Label for="invitationCode">Invitation Code</Label>
              <Row>
                <Col lg={4}>
                  <Input
                    type="text"
                    name="invitationCode"
                    id="invitationCode"
                    value={this.state.invitation_code}
                    onChange={(e) =>
                      this.setState({
                        invitation_code: e.target.value.toUpperCase(),
                      })
                    }
                    placeholder="Enter invitation code"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="">Start Date</Label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  value={this.state.start_date}
                  onChange={(newVal) => {
                    this.setState({
                      start_date: newVal,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="">End Date</Label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  value={this.state.end_date}
                  onChange={(newVal) => {
                    this.setState({
                      end_date: newVal,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormGroup>
          </React.Fragment>
        ),
      },
    ];

    return (
      <Card>
        <Modal isOpen={this.state.modal.open} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            <Icon icon={this.state.modal.success ? "akar-icons:circle-check" : "bx:bx-error-circle"} /> {this.state.modal.success ? "Successful" : "Failed"}
          </ModalHeader>
          <ModalBody>{this.state.modal.success ? "Event created successfully!" : "An error occured."}</ModalBody>
        </Modal>
        <CardBody>
          <Stepper activeStep={this.state.activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}>{step.label}</StepLabel>
                <StepContent>
                  {step.content}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {index === steps.length - 1 ? (
                        <Button variant="contained" onClick={this.handleSubmit} sx={{ mt: 1, mr: 1 }}>
                          Create
                        </Button>
                      ) : (
                        <Button variant="contained" onClick={this.handleNext} sx={{ mt: 1, mr: 1 }}>
                          Next
                        </Button>
                      )}
                      <Button disabled={index === 0} onClick={this.handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardBody>
      </Card>
    );
  }
}

export default AddEventForm;
