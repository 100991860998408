import React, { Component } from "react";
import { Card, CardBody, Col } from "reactstrap";
import axios from "axios";
//Simple bar
import SimpleBar from "simplebar-react";
import moment from "moment";
class RecentlyActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      activities: [],
    };
  }
  componentDidMount() {
    const fetchActivity = async () => {
      try {
        const response = await axios.get("/api/v2/admin/dashboard/activity", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        this.setState({
          activities: response.data.activities,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchActivity();
  }

  render() {
    return (
      <React.Fragment>
        <Col lg={4}>
          <Card style={{ minHeight: "94%" }}>
            <CardBody>
              <h4 className="card-title mb-4">Recent Activity Feed</h4>

              <SimpleBar style={{ maxHeight: "330px" }}>
                <ul className="list-unstyled activity-wid">
                  {this.state.activities.map((activity) => {
                    return (
                      <li className="activity-list">
                        <div className="activity-icon avatar-xs">
                          <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                            <i className="ri-user-2-fill"></i>
                          </span>
                        </div>
                        <div>
                          <div>
                            <h5 className="font-size-13">
                              {moment(activity.active_created_at).format("Do MMM, YY")} <small className="text-muted">{moment(activity.active_created_at).format("LT")}</small>
                            </h5>
                          </div>

                          <div>
                            <p className="text-muted mb-0">{activity.activity_message}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </SimpleBar>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default RecentlyActivity;
