import {
  FORGET_USER,
  FORGET_USER_SUCCESSFUL,
  FORGET_PASSWORD_API_FAILED,
  VALIDATE_CODE,
  VALIDATE_CODE_SUCCESSFUL,
  VALIDATE_CODE_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESSFUL,
  CHANGE_PASSWORD_FAILED,
} from "./actionTypes";

const initialState = {
  forgetError: null,
  message: null,
  loading: false,
  showOtp: false,
  verified: false,
};

const forgetpwd = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        showOtp: false,
        forgetError: null,
      };
      break;
    case FORGET_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
        showOtp: true,
      };
      break;
    case FORGET_PASSWORD_API_FAILED:
      state = {
        ...state,
        loading: false,
        showOtp: false,
        forgetError: action.payload,
      };
      break;
    case VALIDATE_CODE:
      state = {
        ...state,
        message: null,
        user: null,
        loading: true,
        forgetError: null,
      };
      break;
    case VALIDATE_CODE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
        forgetError: null,
        verified: true,
      };
      break;
    case VALIDATE_CODE_FAILED:
      state = {
        ...state,
        loading: false,
        forgetError: action.payload,
        message: null,
      };
      break;
    case CHANGE_PASSWORD:
      state = {
        ...state,
        message: null,
        user: null,
        loading: true,
        forgetError: null,
      };
      break;
    case CHANGE_PASSWORD_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
        forgetError: null,
      };
      break;
    case CHANGE_PASSWORD_FAILED:
      state = {
        ...state,
        loading: false,
        forgetError: action.payload,
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetpwd;
