import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, Label, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser, validateCode, changePassword } from "../../store/actions";

// import images/icons
import logo from "../../assets/images/ic_launcher_round.png";

import { Icon } from "@iconify/react";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "", pass: "", confirmpass: "", showPass: false, showConfirmPass: false };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleChangePass = this.handleChangePass.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    console.log(this.props);
    if (!this.props.showOtp) {
      this.props.forgetUser(values);
    } else {
      // console.log(values);
      this.props.validateCode(values);
    }
  }
  handleChangePass(event, values) {
    values.useremail = this.state.username;
    console.log(values);
    this.props.changePassword(values);
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logo} height="50" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">Reset Password</h4>
                            <p className="text-muted">Reset your password to Crypto Trainer.</p>
                          </div>

                          <div className="p-2 mt-5">
                            {this.props.forgetError && this.props.forgetError ? (
                              <Alert color="danger" className="mb-4">
                                {this.props.forgetError}
                              </Alert>
                            ) : null}
                            {this.props.message ? (
                              <Alert color="success" className="mb-4">
                                {this.props.message}
                              </Alert>
                            ) : null}
                            {this.props.verified ? (
                              <AvForm className="form-horizontal" onValidSubmit={this.handleChangePass}>
                                <div className="auth-form-group-custom mb-4">
                                  <Icon icon="bx:bx-lock" className="auti-custom-input-icon" />

                                  <Label htmlFor="password">New Password</Label>
                                  <AvField
                                    name="password"
                                    value={this.state.pass}
                                    onChange={(e) => {
                                      this.setState({
                                        pass: e.target.value,
                                      });
                                    }}
                                    type={this.state.showPass ? "text" : "password"}
                                    className="form-control"
                                    id="password"
                                    placeholder="Enter new password"
                                  />
                                  <div className="toggle-password">
                                    {!this.state.showPass ? (
                                      <Icon icon="akar-icons:eye" onClick={() => this.setState({ showPass: !this.state.showPass })} />
                                    ) : (
                                      <Icon icon="akar-icons:eye-slashed" onClick={() => this.setState({ showPass: !this.state.showPass })} />
                                    )}
                                  </div>
                                </div>
                                <div className="auth-form-group-custom mb-4">
                                  <Icon icon="bx:bx-lock" className="auti-custom-input-icon" />

                                  <Label htmlFor="confirmpass">Confirm Password</Label>
                                  <AvField
                                    name="confirmpass"
                                    value={this.state.confirmpass}
                                    type={this.state.showConfirmPass ? "text" : "password"}
                                    className="form-control"
                                    id="confirmpass"
                                    placeholder="Enter confirm password"
                                    validate={{ match: { value: "password", errorMessage: "Passwords don't match." } }}
                                  />
                                  <div className="toggle-password">
                                    {!this.state.showConfirmPass ? (
                                      <Icon icon="akar-icons:eye" onClick={() => this.setState({ showConfirmPass: !this.state.showConfirmPass })} />
                                    ) : (
                                      <Icon icon="akar-icons:eye-slashed" onClick={() => this.setState({ showConfirmPass: !this.state.showConfirmPass })} />
                                    )}
                                  </div>
                                </div>

                                <div className="mt-4 text-center">
                                  <Button color="primary" className="w-md waves-effect waves-light" type="submit">
                                    {this.props.loading ? <Spinner size="sm" color="light" /> : "Change Password"}
                                  </Button>
                                </div>
                              </AvForm>
                            ) : (
                              <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                <div className="auth-form-group-custom mb-4">
                                  <i className="ri-mail-line auti-custom-input-icon"></i>
                                  <Label htmlFor="useremail">Email</Label>
                                  <AvField
                                    name="useremail"
                                    value={this.state.username}
                                    onChange={(e) => {
                                      this.setState({
                                        username: e.target.value,
                                      });
                                    }}
                                    type="email"
                                    validate={{ email: true, required: true }}
                                    className="form-control"
                                    id="useremail"
                                    placeholder="Enter email"
                                  />
                                </div>

                                {this.props.showOtp ? (
                                  <div className="auth-form-group-custom mb-4">
                                    <Icon icon="fluent:number-symbol-16-filled" className="auti-custom-input-icon" />

                                    <Label htmlFor="useremail">OTP</Label>
                                    <AvField name="code" type="text" className="form-control" id="code" placeholder="Enter Code" />
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="mt-4 text-center">
                                  {this.props.showOtp ? (
                                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">
                                      {this.props.loading ? <Spinner size="sm" color="light" /> : "Verify"}
                                    </Button>
                                  ) : (
                                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">
                                      {this.props.loading ? <Spinner size="sm" color="light" /> : "Send Code"}
                                    </Button>
                                  )}
                                </div>
                              </AvForm>
                            )}
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              Don't have an account?{" "}
                              <Link to="/login" className="fw-medium text-primary">
                                Log in
                              </Link>
                            </p>
                            <p>
                              © 2021 Crypto Trainer. Crafted with <i className="mdi mdi-heart text-primary"></i> by QuadB Tech
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, forgetError, loading, showOtp, verified } = state.Forget;
  return { message, forgetError, loading, showOtp, verified };
};

export default withRouter(connect(mapStatetoProps, { forgetUser, validateCode, changePassword })(ForgetPasswordPage));
