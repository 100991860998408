import React, { Component } from "react";
import { Card, CardBody, ButtonGroup, Button } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "../Dashboard/dashboard.scss";
import axios from "axios";
import numberWithCommas from "../Dashboard/numberWithCommas";

class UserAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBtn: "daily",
      count: 0,
      yesterday: 0,
      month: 0,
      year: 0,
      series: [],
      options: {
        chart: {
          toolbar: {
            show: false,
          }
        },
        stroke: {
          width: [0, 3],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        colors: ['#5664d2']
      }
    };
  }

  async fetchHourly() {
    try {
      const response = await axios.get(`/api/v2/admin/charts/users?range=24h&granularity=auto`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      this.props.setCount();

      // console.log(response.data);

      let series = [
        {
          name: "Total Users",
          type: "column",
          data: response.data.countArr,
        },
      ];
      let options = { ...this.state.options };
      options.labels = response.data.labels;
      this.setState({
        series,
        options,
        yesterday: numberWithCommas(response.data.yesterday),
        month: numberWithCommas(response.data.month),
        year: numberWithCommas(response.data.year),
      });
    } catch (error) {
      console.log(error);
    }
  }
  async fetchWeekly() {
    try {
      const response = await axios.get(`/api/v2/admin/charts/users?range=1m&granularity=auto`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);

      let series = [
        {
          name: "Total Users",
          type: "column",
          data: response.data.countArr,
        },
      ];
      let options = { ...this.state.options };
      options.labels = response.data.labels;
      this.setState({
        series,
        options,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async fetchMonthly() {
    try {
      const response = await axios.get(`/api/v2/admin/charts/users?range=1y&granularity=auto`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);

      let series = [
        {
          name: "Total Users",
          type: "column",
          data: response.data.countArr,
        },
      ];
      let options = { ...this.state.options };
      options.labels = response.data.labels;
      this.setState({
        series,
        options,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    this.fetchHourly();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeBtn !== this.state.activeBtn && this.state.count) {
      switch (this.state.activeBtn) {
        case "daily":
          this.fetchHourly();
          break;
        case "weekly":
          this.fetchWeekly();
          break;
        case "monthly":
          this.fetchMonthly();
          break;
        default:
          break;
      }
      // this.fetchWeekly();
    }
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-end d-none d-md-inline-block">
              <ButtonGroup className="toggle-graph mb-2">
                <Button
                  size="sm"
                  color="light"
                  type="button"
                  id="daily"
                  className={this.state.activeBtn === "daily" ? "active" : ""}
                  onClick={(e) => {
                    this.setState({
                      activeBtn: e.target.id,
                      count: this.state.count + 1,
                    });
                  }}
                >
                  Yesterday
                </Button>
                <Button
                  size="sm"
                  color="light"
                  type="button"
                  id="weekly"
                  className={this.state.activeBtn === "weekly" ? "active" : ""}
                  onClick={(e) => {
                    this.setState({
                      activeBtn: e.target.id,
                      count: this.state.count + 1,
                    });
                  }}
                >
                  Weekly
                </Button>
                <Button
                  size="sm"
                  color="light"
                  type="button"
                  id="monthly"
                  className={this.state.activeBtn === "monthly" ? "active" : ""}
                  onClick={(e) => {
                    this.setState({
                      activeBtn: e.target.id,
                      count: this.state.count + 1,
                    });
                  }}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </div>
            <h4 className="card-title mb-4">User Analytics</h4>
            <div>
              <div id="line-column-chart" className="apex-charts" dir="ltr">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="280" />
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default UserAnalytics;
