import React, { Component } from "react";

import { Col, Card, CardBody, Container, Row, Button } from "reactstrap";
import { Icon } from "@iconify/react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import axios from "axios";
import Switch from "@mui/material/Switch";
import numberWithCommas from "../Dashboard/numberWithCommas";
import Backdrop from "@mui/material/Backdrop";

import CircularProgress from "@mui/material/CircularProgress";

class ViewTrending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "View Trending", link: "#" },
      ],
      menu: false,
      trending: [],
      open: true,
      drag: false,
    };
    this.changeTrending = this.changeTrending.bind(this);
    this.draggable = this.draggable.bind(this);
    this.updateTrending = this.updateTrending.bind(this);
  }

  async changeTrending(id) {
    await axios.post(
      `/api/v2/admin/trending/toggle`,
      {
        trending_id: id,
      },
      {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      }
    );
  }
  async updateTrending() {
    try {
      const cryptos = document.querySelectorAll(".cryptos");
      let trending = [];
      cryptos.forEach((e) => {
        trending.push(JSON.parse(e.dataset.crypto));
      });
      // console.log(trending);
      this.setState({
        trending: trending,
        open: true,
      });
      await axios.post(
        `/api/v2/admin/trending/reorder`,
        {
          new_trending: trending,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        }
      );
      this.setState({
        open: false,
        drag: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    const getTrending = async () => {
      try {
        const response = await axios.get(`/api/v2/admin/trending`, {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        this.setState({
          trending: response.data.trending,
          open: false,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getTrending();
  }
  draggable() {
    const drag = this.state.drag;
    if (drag) {
      try {
        let dragSrcEl = null;
        function handleDragStart(e) {
          dragSrcEl = this;
          e.dataTransfer.effectAllowed = "move";
          e.dataTransfer.setData("text/html", this.outerHTML);
        }
        function handleDragOver(e) {
          if (e.preventDefault) {
            e.preventDefault();
          }
          e.dataTransfer.dropEffect = "move";
          return false;
        }

        function handleDrop(e) {
          if (e.stopPropagation) {
            e.stopPropagation();
          }
          if (dragSrcEl !== this) {
            this.parentNode.removeChild(dragSrcEl);
            let dropHTML = e.dataTransfer.getData("text/html");
            this.insertAdjacentHTML("beforebegin", dropHTML);
            let dropElem = this.previousSibling;
            addDnDHandlers(dropElem);
          }
          return false;
        }
        function addDnDHandlers(elem) {
          elem.addEventListener("dragstart", handleDragStart, false);
          elem.addEventListener("dragover", handleDragOver, false);
          elem.addEventListener("drop", handleDrop, false);
          elem.setAttribute("draggable", drag);
        }

        let cols = document.querySelectorAll("#columns .column");
        [].forEach.call(cols, addDnDHandlers);
      } catch (err) {
        console.log(err);
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>

        <div className="page-content">
          <Container fluid className="mb-4">
            <Breadcrumbs title="View Trending" breadcrumbItems={this.state.breadcrumbItems} />

            <Row id="columns">
              <Col lg={8} md={10} className="m-auto pb-2 text-end">
                {!this.state.drag ? (
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState({ drag: true }, () => this.draggable());
                    }}
                  >
                    Reorder
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button color="secondary" onClick={() => this.setState({ drag: false })}>
                      Cancel
                    </Button>{" "}
                    <Button color="primary" onClick={this.updateTrending}>
                      Save
                    </Button>{" "}
                  </React.Fragment>
                )}
              </Col>
              {this.state.trending.map((item) => {
                const label = { inputProps: { "aria-label": "Toggle" } };
                return (
                  <Col lg={8} md={10} key={item.trending_id} className="m-auto column" draggable={this.state.drag} style={{ cursor: this.state.drag ? "grab" : "" }}>
                    <Card className="mb-2">
                      <CardBody>
                        <Row className="align-items-center cryptos" data-crypto={JSON.stringify(item)}>
                          <Col xs={4} className="d-flex text-center m-auto">
                            {/* {this.state.drag === true && <Icon icon="iconoir:drag-hand-gesture" width="20" style={{ position: "relative", top: 5, left: -3 }} />} */}
                            <img src={item.crypto_image} width="30" height="30" style={{ borderRadius: "100%" }} alt="" />
                            <div className="d-flex flex-column px-2 ">
                              <div>{item.crypto_name}</div> <div style={{ textAlign: "left" }}>{item.crypto_symbol}</div>
                            </div>
                          </Col>
                          <Col xs={4} className="text-center m-auto">
                            <span>₹{numberWithCommas(item.crypto_price.slice(1, item.crypto_price.length))}</span>
                          </Col>
                          <Col xs={4} className="text-center m-auto">
                            <Switch {...label} defaultChecked={item.is_trending} onChange={() => this.changeTrending(item.trending_id)} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ViewTrending;
