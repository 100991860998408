import React, { Component } from "react";
import { Card, CardBody, CardImg, CardText, Button, CardTitle, Col, Input, InputGroup, ListGroup, ListGroupItem } from "reactstrap";
import "./Events.scss";
import Leaderboard from "./Leaderboard";
import axios from "axios";
import SimpleBar from "simplebar-react";
import moment from "moment";

class EventCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      events: [],
      searchTerm: "",
      open: false,
      event_id: "",
    };
    this.search = this.search.bind(this);
    this.toggle = this.toggle.bind(this);
    this.fetchEvents = this.fetchEvents.bind(this);
  }
  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }
  async search() {
    if (!this.state.searchTerm.length) {
      this.fetchEvents();
      return;
    }

    try {
      const response = await axios.get(`/api/v2/admin/events/search?s=${this.state.searchTerm}`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      this.setState({
        events: response.data.results,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async fetchEvents() {
    try {
      const response = await axios.get("/api/v2/admin/events", {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);
      this.setState(
        {
          events: response.data.events,
        },
        () => {
          this.props.toggle();
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    this.fetchEvents();
  }
  render() {
    return (
      <React.Fragment>
        <Col lg={12} className="mb-3">
          <InputGroup>
            <Input
              type="text"
              name="searchTerm"
              id="searchTerm"
              value={this.state.searchTerm}
              onChange={(e) => {
                this.setState({
                  searchTerm: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.search();
                }
              }}
              placeholder="Enter event title"
            />
            <Button color="primary" onClick={this.search}>
              Search
            </Button>
          </InputGroup>
        </Col>
        <Leaderboard isOpen={this.state.open} toggle={this.toggle} event_id={this.state.event_id} />
        {this.state.events.map((event, index) => {
          return (
            <Col lg={4} md={6} xs={12} className="event-cards" key={index}>
              <Card>
                <CardImg variant="top" src={event.banner_image} />
                <CardBody>
                  <CardTitle className="event-title">{event.title}</CardTitle>
                  <CardText>
                    <img src={event.logo} alt="logo" className="logo-host" /> {event.host_name}
                  </CardText>
                  <SimpleBar style={{ maxHeight: "100px", minHeight: "100px" }}>
                    <CardText>{event.description}</CardText>
                  </SimpleBar>
                </CardBody>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>Participants: {event.participants}</ListGroupItem>
                  <ListGroupItem>
                    Invitation Code: <strong>{event.invitation_code}</strong>
                  </ListGroupItem>
                  <ListGroupItem>Created: {moment(event.created).format("Do MMM YY")}</ListGroupItem>
                  <ListGroupItem>
                    Status:{" "}
                    <span className={"text-capitalize event-badge event-badge-" + (event.status === "ongoing" ? "success" : event.status === "completed" ? "danger" : "warning")}>{event.status}</span>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState(
                          {
                            event_id: event.event_id,
                          },
                          () => this.toggle()
                        );
                      }}
                    >
                      View Leaderboard
                    </Button>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          );
        })}
        {this.state.events.length === 0 && <div className="text-center text-muted my-5">No match found.</div>}
      </React.Fragment>
    );
  }
}

export default EventCards;
