import React, { Component } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import CTTForm from "./CTTForm";
import CTTAnalytics from "./CTTAnalytics";
import CTTReports from "./CTTReports";
// import numberWithCommas from "../Dashboard/numberWithCommas";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

class CTT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "CTT", link: "#" },
      ],
      open: true,
      count: 0,
    };
    this.setCount = this.setCount.bind(this);
  }
  setCount() {
    this.setState({
      count: this.state.count + 1,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.count !== this.state.count) {
      if (this.state.count >= 2) {
        this.setState({
          open: false,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="CTT" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col md="12" lg="8">
                <CTTAnalytics setCount={this.setCount} />
              </Col>
              <Col md="12" lg="4">
                <Row style={{ height: "100%" }}>
                  {/* <Col md="6" lg="12">
                    <TotalUsers total_users={this.state.total_users} />
                  </Col> */}
                  <Col md="12" lg="12">
                    <CTTReports setCount={this.setCount} />
                  </Col>
                </Row>
              </Col>

              <Col xs="12" lg="8">
                <Card>
                  <CardBody>
                    <CTTForm />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CTT;
