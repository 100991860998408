import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CHANGE_PASSWORD, FORGET_USER, VALIDATE_CODE } from "./actionTypes";
import { forgetUserSuccessful, userForgetPasswordError, validateCodeSuccessful, validateCodeFailed, changePasswordSuccessful, changePasswordFailed } from "./actions";

// AUTH related methods
import { validate_code, postForgetPwd, change_password } from "../../../helpers/fackBackend_Helper";
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";

//Initilize firebase
// const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user } }) {
  try {
    const response = yield call(postForgetPwd, `/api/v2/admin/forgotPassword/getPassword/${user.useremail}`);
    if (response.status === "true") {
      yield put(forgetUserSuccessful("A code has been shared to this mail address."));
    } else {
      yield put(userForgetPasswordError("No account found with this email address."));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* validateCode({ payload: { user } }) {
  console.log(user);
  try {
    const response = yield call(validate_code, `/api/v2/admin/forgotPassword/${user.code}/${user.useremail}`);
    if (response.status === "true") {
      yield put(validateCodeSuccessful("Verified successfully."));
      console.log(response);
    } else {
      yield put(validateCodeFailed("Invalid code. Please try again."));
      console.log(response);
    }
  } catch (error) {
    yield put(validateCodeFailed(error));
  }
}

function* changePassword({ payload: { user } }) {
  console.log(user);
  try {
    const response = yield call(change_password, `/api/v2/admin/forgotPassword/passUpdate/${user.useremail}/${user.password}`);
    if (response.status === "true") {
      yield put(changePasswordSuccessful("Password changed successfully."));
      console.log(response);
    } else {
      yield put(changePasswordFailed("An error occured. Please try again."));
      console.log(response);
    }
  } catch (error) {
    yield put(changePasswordFailed(error));
  }
}

export function* watchUserForget() {
  yield takeEvery(FORGET_USER, forgetUser);
}

export function* watchCode() {
  yield takeEvery(VALIDATE_CODE, validateCode);
}
export function* changePass() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

function* forgetSaga() {
  yield all([fork(watchUserForget), fork(watchCode), fork(changePass)]);
}

export default forgetSaga;
