import React, { Component } from "react";

import { Col, Card, CardBody, Container } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import axios from "axios";
import SimpleBar from "simplebar-react";
import Backdrop from "@mui/material/Backdrop";

import CircularProgress from "@mui/material/CircularProgress";
class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "Market", link: "#" },
      ],
      menu: false,
      open: true,
      rows: [],
    };
  }

  componentDidMount() {
    const getMarket = async () => {
      try {
        const response = await axios.get(`/api/v2/admin/market`, {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        let rows = [];
        this.setState({ open: false });
        response.data.market.forEach((t) => {
          rows.push({
            profile: (
              <img
                src={t.crypto_image || "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"}
                width="30"
                height="30"
                style={{ borderRadius: "100%" }}
                alt=""
              />
            ),
            crypto_name: t.crypto_name,
            crypto_symbol: t.crypto_symbol,
            crypto_price: t.crypto_price,
          });
        });
        this.setState({
          rows,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getMarket();
  }
  render() {
    const data = {
      columns: [
        {
          dataField: "profile",
          text: "Crypto Image",
        },
        {
          dataField: "crypto_name",
          text: "Crypto Name",
        },
        {
          dataField: "crypto_symbol",
          text: "Symbol",
        },

        {
          dataField: "crypto_price",
          text: "Crypto Price",
        },
      ],
      rows: this.state.rows,
    };

    const options = {
      hideSizePerPage: true,
      hidePageListOnlyOnePage: false,
    };
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Market" breadcrumbItems={this.state.breadcrumbItems} />
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <div className="float-end">
                    <Link to="#" className="btn btn-primary btn-sm">
                      View more
                    </Link>
                  </div> */}
                  <h4 className="card-title mb-4">Market</h4>
                  <div className="table-responsive">
                    <SimpleBar style={{ maxWidth: "100%", maxHeight: "100%" }}>
                      <BootstrapTable keyField="id" data={data.rows} columns={data.columns} pagination={paginationFactory(options)} />
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Market;
