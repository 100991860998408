import React, { Component } from "react";

import { Col, Container, Row } from "reactstrap";
// import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import UserAnalytics from "./UserAnalytics";
import UserGraphs from "./UserGraphs";
// import TotalUsers from "./TotalUsers";
// import numberWithCommas from "../Dashboard/numberWithCommas";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      count: 0,
      breadcrumbItems: [
        { title: "Crypto Trainer", link: "/" },
        { title: "Analytics", link: "#" },
      ],
    };
    this.setCount = this.setCount.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
  }
  setCount() {
    this.setState({
      count: this.state.count + 1,
    });
  }
  toggleLoader() {
    this.setState({
      open: !this.state.open,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.count !== this.state.count) {
      if (this.state.count >= 3) {
        this.setState({
          open: false,
        });
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        {/* <Backdrop style={{ zIndex: 9999 }} open={this.state.open}>
          <CircularProgress style={{ color: "#5664D2" }} />
        </Backdrop> */}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Analytics" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col md="12" lg="8">
                <UserAnalytics setCount={this.setCount} toggleLoader={this.toggleLoader} />
              </Col>
              <Col md="12" lg="4">
                <Row style={{ height: "100%" }}>
                  <Col md="12" lg="12">
                    <UserGraphs setCount={this.setCount} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Analytics;
