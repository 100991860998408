import React, { Component } from "react";
import { Card, CardBody, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleBar from "simplebar-react";
import numberWithCommas from "./numberWithCommas";
class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      totalParticipants: 0,
      events: [],
    };
  }
  componentDidMount() {
    const fetchEvents = async () => {
      try {
        const response = await axios.get("/api/v2/admin/dashboard/events", {
          headers: {
            "x-auth-token": localStorage.getItem("authToken"),
          },
        });
        // console.log(response.data);
        this.setState({
          totalParticipants: numberWithCommas(response.data.total_participants),
          events: response.data.events,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchEvents();
  }
  render() {
    return (
      <React.Fragment>
        <Col lg={4}>
          <Card style={{ minHeight: "94%" }}>
            <CardBody>
              <h4 className="card-title mb-3">Events</h4>

              <div>
                <div className="text-center">
                  <p className="mb-2">Total participants</p>
                  <h4>{this.state.totalParticipants}</h4>
                  {/* <div className="text-success">
                    <i className="mdi mdi-menu-up font-size-14"> </i>2.2 %
                  </div> */}
                </div>
                <SimpleBar style={{ maxHeight: 220 }}>
                  <div className="table-responsive mt-2">
                    <SimpleBar style={{ maxWidth: "100%" }}>
                      <Table hover className=" mb-0 table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Event Name</th>
                            <th>Participants</th>
                          </tr>
                        </thead>
                        <tbody style={{ maxHeight: "30px" }}>
                          {this.state.events.map((event) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className="avatar-xs">
                                      <div className="avatar-title rounded-circle bg-light">
                                        <img src={event.banner_image} alt="" height="20" width="20" style={{ borderRadius: "100%" }} />
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <h5 className="font-size-14 mb-0">{event.title}</h5>
                                    <h6 className="font-size-12 text-muted">{event.host_name}</h6>
                                  </td>

                                  <td className="text-center">
                                    <p className="text-muted mb-0">{numberWithCommas(event.participants)}</p>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </SimpleBar>
                  </div>
                </SimpleBar>

                {/* <div className="text-center mt-4">
                  <Link to="#" className="btn btn-primary btn-sm">
                    View more
                  </Link>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default Events;
