import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import axios from "axios";

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.event_id !== this.props.event_id) {
      console.log(this.props.event_id);
      const fetchLeaderboard = async () => {
        try {
          console.log(this.props);
          let response = await axios.get(`/api/v2/admin/events/leaderboards?event_id=${this.props.event_id}`, {
            headers: {
              "x-auth-token": localStorage.getItem("authToken"),
            },
          });
          response.data.msg.forEach((msg) => {
            msg.index = `#${msg.index}`;
          });
          this.setState({
            rows: response.data.msg,
          });
        } catch (err) {
          console.log(err);
        }
      };
      fetchLeaderboard();
    }
  }
  render() {
    const data = {
      columns: [
        {
          dataField: "index",
          text: "Rank",
        },
        {
          dataField: "name",
          text: "Name",
        },
        {
          dataField: "percent",
          text: "Percent",
        },
      ],
      rows: this.state.rows,
    };

    const options = {
      hideSizePerPage: true,
      hidePageListOnlyOnePage: false,
    };
    return (
      <Modal {...this.props} fullscreen="xl" size="xl">
        <ModalHeader toggle={this.props.toggle}>Leaderboard</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <BootstrapTable keyField="id" data={data.rows} columns={data.columns} pagination={paginationFactory(options)} />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default Leaderboard;
