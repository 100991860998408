import React, { Component, useRef } from "react";
import { Card, CardBody, FormGroup, Label, Input, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { Icon } from "@iconify/react";

import axios from "axios";

const CustomData = ({ changeKeys, keyIdx, keys, updateKey, updateVal }) => {
  let check = useRef(0);
  return (
    <Row className="mb-2">
      <Col md={6}>
        <Input
          type="text"
          placeholder="Key"
          value={keys.key}
          onChange={(e) => {
            // console.log(keyIdx);
            updateKey(e.target.value, keyIdx);
            check.current = check.current + 1;

            if (e.target.value.length === 1 && check.current === 1) {
              changeKeys(1);
            }
            if (!e.target.value.length && keyIdx !== 0) {
              changeKeys(0, keyIdx);
            }
            if (!e.target.value.length) {
              check.current = 0;
            }
          }}
        />
      </Col>
      <Col md={6}>
        <Input type="text" placeholder="Value" value={keys.val} onChange={(e) => updateVal(e.target.value, keyIdx)} />
      </Col>
    </Row>
  );
};

class NotificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      modal: {
        open: false,
        success: false,
      },
      showAddUser: false,
      title: "",
      name: "",
      image: "",
      email: "",
      keys: [
        {
          key: "",
          val: "",
        },
      ],
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.changeKeys = this.changeKeys.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
    this.updateKey = this.updateKey.bind(this);
    this.updateVal = this.updateVal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
      },
    });
  }
  handleNext() {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  }
  handleBack() {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  }
  handleReset() {
    this.setState({
      activeStep: 0,
    });
  }
  onChangeValue(e) {
    this.setState({
      showAddUser: e.target.value === "user" ? true : false,
    });
  }
  changeKeys(x, i) {
    if (x)
      this.setState({
        keys: [...this.state.keys, { key: "", val: "" }],
      });
    else {
      let keys = [...this.state.keys];
      keys.splice(i, 1);
      this.setState({
        keys,
      });
    }
  }

  updateKey(key, idx) {
    let keys = [...this.state.keys];
    keys[idx].key = key;
    this.setState({
      keys,
    });
  }
  updateVal(val, idx) {
    let keys = [...this.state.keys];
    keys[idx].val = val;
    this.setState({
      keys,
    });
  }

  async sendNotification() {
    let data = { ...this.state };
    delete data.activeStep;
    delete data.showAddUser;
    delete data.modal;
    const key = data.keys[0].key,
      val = data.keys[0].val;
    let action = {};
    action[key] = val;

    if (!data.email.length) {
      try {
        await axios.post(
          "/api/v2/admin/notifications/sendAll",
          {
            title: data.title,
            body: data.name,
            image: data.image,
            ...action,
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("authToken"),
            },
          }
        );
        this.setState({
          ...this.state,
          activeStep: 0,
          modal: {
            success: true,
            open: true,
          },

          title: "",
          name: "",
          image: "",
          email: "",
          keys: [
            {
              key: "",
              val: "",
            },
          ],
        });
      } catch (err) {
        console.log(err);
        this.setState({
          modal: {
            success: false,
            open: true,
          },
        });
      }
    } else {
      try {
        await axios.post(
          `/api/v2/admin/notifications/send/${data.email}`,
          {
            title: data.title,
            body: data.name,
            image: data.image,
            ...action,
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("authToken"),
            },
          }
        );
        this.setState({
          ...this.state,
          activeStep: 0,
          modal: {
            success: true,
            open: true,
          },

          title: "",
          name: "",
          image: "",
          email: "",
          keys: [
            {
              key: "",
              val: "",
            },
          ],
        });
      } catch (err) {
        console.log(err);
        this.setState({
          modal: {
            success: false,
            open: true,
          },
        });
      }
    }
  }
  render() {
    const steps = [
      {
        label: "Notification",
        content: (
          <React.Fragment>
            <FormGroup className="mb-2">
              <Label for="notificationTitle">Notification title</Label>
              <Input
                type="text"
                name="notificationTitle"
                id="notificationTitle"
                value={this.state.title}
                onChange={(e) =>
                  this.setState({
                    title: e.target.value,
                  })
                }
                placeholder="Enter title"
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="notificationImage">Notification image (optional)</Label>
              <Input
                type="text"
                name="notificationImage"
                id="notificationImage"
                value={this.state.image}
                onChange={(e) =>
                  this.setState({
                    image: e.target.value,
                  })
                }
                placeholder="Enter notification image URL"
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="notificationName">Notification body</Label>
              {/* <Input
                type="text"
                name="notificationName"
                id="notificationName"
                value={this.state.name}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                placeholder="Enter notification body"
              /> */}
              <textarea
                className="form-control"
                id="description"
                rows="3"
                placeholder="Enter notification body"
                value={this.state.name}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
              ></textarea>
            </FormGroup>
          </React.Fragment>
        ),
      },
      {
        label: "Target",
        content: (
          <React.Fragment>
            <RadioGroup row aria-label="target" name="row-radio-buttons-group" onChange={this.onChangeValue}>
              <FormControlLabel value="user" control={<Radio />} label="User" />
              <FormControlLabel value="all" control={<Radio />} label="All" />
            </RadioGroup>
            {this.state.showAddUser && (
              <FormGroup className="mb-2">
                <Label for="emailUser">Enter email of user</Label>
                <Input
                  type="text"
                  name="emailUser"
                  id="emailUser"
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({
                      email: e.target.value,
                    })
                  }
                  placeholder="abc@gmail.com"
                />
              </FormGroup>
            )}
          </React.Fragment>
        ),
      },
      {
        label: "Additional options (optional)",
        content: (
          <React.Fragment>
            <FormGroup className="mb-2">
              <Label for="notificationTitle">Custom Data</Label>
              <Row>
                {this.state.keys.map((val, idx) => {
                  return <CustomData key={idx} keyIdx={idx} changeKeys={this.changeKeys} keys={this.state.keys[idx]} updateKey={this.updateKey} updateVal={this.updateVal} />;
                })}
              </Row>
            </FormGroup>
          </React.Fragment>
        ),
      },
    ];

    return (
      <Card>
        <Modal isOpen={this.state.modal.open} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            <Icon icon={this.state.modal.success ? "akar-icons:circle-check" : "bx:bx-error-circle"} /> {this.state.modal.success ? "Successful" : "Failed"}
          </ModalHeader>
          <ModalBody>{this.state.modal.success ? "Notification sent successfully!" : "An error occured."}</ModalBody>
        </Modal>
        <CardBody>
          <Stepper activeStep={this.state.activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}>{step.label}</StepLabel>
                <StepContent>
                  {step.content}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {index === steps.length - 1 ? (
                        <Button variant="contained" onClick={this.sendNotification} sx={{ mt: 1, mr: 1 }}>
                          Send
                        </Button>
                      ) : (
                        <Button variant="contained" onClick={this.handleNext} sx={{ mt: 1, mr: 1 }}>
                          Next
                        </Button>
                      )}
                      <Button disabled={index === 0} onClick={this.handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardBody>
      </Card>
    );
  }
}

export default NotificationForm;
