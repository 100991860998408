import React, { Component } from "react";
import { Form, FormGroup, Button, Input, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import axios from "axios";
import moment from "moment";
import SimpleBar from "simplebar-react";
import paginationFactory from "react-bootstrap-table2-paginator";
import numberWithCommas from "../Dashboard/numberWithCommas";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      search_term: "",
      total_users: 0,
      rows: [],
      sort: "full_name",
      order: "asc",
      modal: false,
      action: {
        user_id: "",
        full_name: "",
        message: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  async handleAction(user_id) {
    const cta_id = localStorage.getItem("cta_id");
    // console.log(user_id);
    const url = this.state.action.message === "reset" ? "/api/v2/admin/users/reset" : "/api/v2/admin/users/block";
    const response = await axios.post(
      url,
      {
        user_id,
        cta_id,
      },
      {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      }
    );
    // console.log(response.data);
    this.setState({
      modal: false,
    });
    if (response.data.status === "true") {
      this.handleSubmit();
    }
  }

  async getUsers() {
    try {
      const response = await axios.get(`/api/v2/admin/users?page=1&sort_by=${this.state.sort}&order_by=${this.state.order}`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);
      this.props.setCount();
      this.props.toggleLoader();

      let rows = [];
      response.data.users.forEach((t) => {
        const date = moment(t.creation_date).format("Do MMM YY");
        rows.push({
          user_id: t.user_id,
          profile: (
            <div className="text-center">
              <img src={t.image || "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"} width="30" height="30" style={{ borderRadius: "100%" }} alt="" />
            </div>
          ),
          full_name: <p style={{ lineHeight: "1.2rem" }}>{t.full_name}</p>,
          email: t.email || "-",
          phone_number: t.phone_number || "-",
          available_cash: `₹${numberWithCommas(parseFloat(Number(t.available_cash).toFixed(4)))}` || "-",
          ctt: `${numberWithCommas(t.ctt)}` || "-",
          referral_code: t.referral_code,
          creation_date: date,
          actions: (
            <div className="d-flex">
              <div
                className="badge badge-soft-success font-size-14 me-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.toggle();
                  this.setState({
                    action: {
                      user_id: t.user_id,
                      full_name: t.full_name,
                      message: "reset",
                    },
                  });
                }}
              >
                Reset
              </div>
              <div
                className="badge badge-soft-danger font-size-14"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.toggle();

                  this.setState({
                    action: {
                      user_id: t.user_id,
                      full_name: t.full_name,
                      message: !t.is_blocked ? "block" : "unblock",
                    },
                  });
                }}
              >
                {!t.is_blocked ? "Block" : "Unblock"}
              </div>
            </div>
          ),
        });
      });
      this.setState({
        rows,
        total_users: Number(response.data.total_users),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleSubmit(e) {
    if (e) e.preventDefault();
    this.props.toggleLoader();
    try {
      const response = await axios.get(`/api/v2/admin/users/search?search=${this.state.search_term}&page=1&sort_by=${this.state.sort}&order_by=${this.state.order}`, {
        headers: {
          "x-auth-token": localStorage.getItem("authToken"),
        },
      });
      // console.log(response.data);
      this.props.toggleLoader();

      let rows = [];
      response.data.search_results.forEach((t) => {
        const date = moment(t.creation_date).format("Do MMM YY");
        rows.push({
          user_id: t.user_id,
          profile: (
            <div className="text-center">
              <img src={t.image || "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"} width="30" height="30" style={{ borderRadius: "100%" }} alt="" />
            </div>
          ),
          full_name: <p style={{ lineHeight: "1.2rem" }}>{t.full_name}</p>,
          email: t.email || "-",
          phone_number: t.phone_number || "-",
          available_cash: `₹${numberWithCommas(parseFloat(Number(t.available_cash).toFixed(4)))}` || "-",
          ctt: `${numberWithCommas(t.ctt)}` || "-",
          referral_code: t.referral_code,
          creation_date: date,
          actions: (
            <div className="d-flex">
              <div
                className="badge badge-soft-success font-size-14 me-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.toggle();
                  this.setState({
                    action: {
                      user_id: t.user_id,
                      full_name: t.full_name,
                      message: "reset",
                    },
                  });
                }}
              >
                Reset
              </div>
              <div
                className="badge badge-soft-danger font-size-14"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.toggle();

                  this.setState({
                    action: {
                      user_id: t.user_id,
                      full_name: t.full_name,
                      message: !t.is_blocked ? "block" : "unblock",
                    },
                  });
                }}
              >
                {!t.is_blocked ? "Block" : "Unblock"}
              </div>
            </div>
          ),
        });
      });
      this.setState({
        rows,
        total_users: Number(response.data.total_users),
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  render() {
    const data = {
      columns: [
        {
          dataField: "user_id",
          text: "User ID",
        },
        {
          dataField: "profile",
          text: "Profile",
        },
        {
          dataField: "full_name",
          text: "Name",
        },
        {
          dataField: "email",
          text: "Email",
        },
        {
          dataField: "phone_number",
          text: "Phone Number",
        },
        {
          dataField: "available_cash",
          text: "Available Cash",
        },
        {
          dataField: "ctt",
          text: "CTT",
        },
        {
          dataField: "referral_code",
          text: "Referral Code",
        },
        {
          dataField: "creation_date",
          text: "Creation Date",
        },
        {
          dataField: "actions",
          text: "Actions",
        },
      ],
    };
    const options = {
      hideSizePerPage: true,
      hidePageListOnlyOnePage: false,
    };
    return (
      <React.Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
          <ModalBody>
            Are you sure you want to {this.state.action.message} <strong>{this.state.action.full_name}</strong>'s account?
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                this.handleAction(this.state.action.user_id);
              }}
              className="text-capitalize"
            >
              {this.state.action.message}
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
          <Col sm={12} md={5} style={{ marginBottom: 10 }}>
            <Form inline className="d-flex gap-2" onSubmit={this.handleSubmit}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Input
                  type="text"
                  placeholder="Search for a user..."
                  value={this.state.search_term}
                  onChange={(e) => {
                    this.setState({
                      search_term: e.target.value,
                    });
                    if (e.target.value === "") {
                      this.getUsers();
                    }
                  }}
                />
              </FormGroup>
              <Button style={{ background: "#5664D2" }}>Search</Button>
            </Form>
          </Col>
          <Col sm={12} md={7}>
            <Row style={{ justifyContent: "flex-end" }}>
              Sort By:
              <Col sm={3}>
                <select
                  className="form-select form-select-sm"
                  onChange={(e) => {
                    this.setState(
                      {
                        sort: e.target.value,
                      },
                      () => {
                        this.getUsers();
                      }
                    );
                  }}
                  value={this.state.sort}
                >
                  <option value="full_name">Name</option>
                  <option value="creation_date">Creation Date</option>
                  <option value="total_value">Total Value</option>
                  <option value="available_cash">Available Cash</option>
                  <option value="ctt">CTT</option>
                </select>
              </Col>
              Order By:
              <Col sm={3}>
                <select
                  className="form-select form-select-sm"
                  onChange={(e) => {
                    this.setState(
                      {
                        order: e.target.value,
                      },
                      () => {
                        this.getUsers();
                      }
                    );
                  }}
                  value={this.state.order}
                >
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </Col>
            </Row>
          </Col>
        </Row>

        <Col lg={12}>
          <div className="table-responsive">
            <SimpleBar style={{ maxWidth: "100%", maxHeight: "100%" }}>
              <BootstrapTable keyField="id" data={this.state.rows} columns={data.columns} pagination={paginationFactory(options)} />
            </SimpleBar>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default User;
