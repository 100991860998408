import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";
import packageJson from '../../package.json';

// axios.defaults.baseURL = "https://cryptotrainerapi.finstreet.in";
axios.defaults.baseURL = packageJson.env === 'dev' ? "https://cryptotrainertestapi.finstreet.in" : "https://cryptotrainerapi.finstreet.in"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = async (url, data) => {
  console.log(JSON.stringify(data));
  return axios
    .post(url, data, {
      headers: {
        "Access-Control-Expose-Headers": "*",
      },
    })
    .then((response) => {
      localStorage.setItem("authToken", response.headers["x-auth-token"]);
      localStorage.setItem("cta_id", response.data.cta_id);

      if (response.status === 400 || response.status === 401 || response.status === 404 || response.status === 500) throw response.data;

      return response.data;
    })
    .catch((err) => {
      console.log(err.response);
      throw err.response.data;
    });
};

// postForgetPwd
const postForgetPwd = (url) => {
  return axios
    .get(url)
    .then((response) => {
      console.log(response);
      if (response.status === 400 || response.status === 500) throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err.response);
      return err.response;
    });
};

// validate code
const validate_code = (url) => {
  return axios
    .post(url, { data: "" })
    .then((response) => {
      console.log(response);
      if (response.status === 400 || response.status === 500) throw response.data;
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });
};
// change password
const change_password = (url) => {
  return axios
    .post(url, { data: "" })
    .then((response) => {
      console.log(response);
      if (response.status === 400 || response.status === 500) throw response.data;
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });
};

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = (event) => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = (event) => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = (event) => del(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = (id) => get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = (order) => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = (order) => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = (order) => del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") => get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = (message) => post(url.ADD_MESSAGE, message);

export { getLoggedInUser, isUserAuthenticated, postRegister, postLogin, postForgetPwd, validate_code, change_password };
